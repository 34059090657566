
import Oidc from 'oidc-client';
import 'babel-polyfill';
import i18n from '../../lang/index';

var mgr = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore(),
  authority: 'https://localhost:44391',
  client_id: 'applicantClient',
  redirect_uri: window.location.origin + '/public/callback.html',
  response_type: 'id_token token',
  scope: 'openid profile email tenant_clientId dataapi',
  post_logout_redirect_uri: window.location.origin + '/index.html?type=0',
  silent_redirect_uri: window.location.origin + '/public/silent-renew.html',
  accessTokenExpiringNotificationTime: 1,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true
})

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;
const signedOut = 'signed out';

mgr.events.addUserLoaded(function (user) {
  console.log('New User Loaded：', arguments);
  console.log('Acess_token: ', user.access_token)
});

mgr.events.addAccessTokenExpiring(function () {
  console.log('AccessToken Expiring：', arguments);
});

mgr.events.addAccessTokenExpired(function () {
  console.log('AccessToken Expired：', arguments);
  alert('Session expired. Going out!');
  mgr.signoutRedirect().then(function (resp) {
    console.log(signedOut, resp);
  }).catch(function (err) {
    console.log(err)
  })
});

mgr.events.addSilentRenewError(function () {
  console.error('Silent Renew Error：', arguments);
});

mgr.events.addUserSignedOut(function () {
  alert(i18n.t("lang.modal.expirationSessionTitle"));

    mgr.signoutRedirect().then(function (resp) {
      console.log(signedOut, resp);
    }).catch(function (err) {
      console.log(err)
    })

});

export default class SecurityService {

  // Renew the token manually
  renewToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.signinSilent().then( user => {
        if (user == null) {
          self.signIn();
        } else {
          return resolve(user);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Get the user who is logged in
  getUser() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Check if there is any user logged in
  getSignedIn() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(false);
        } else {
          return resolve(true);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    mgr.signinRedirect().catch( err => {
      console.log(err);
    });
  }

  signInPromise(){
    return new Promise((resolve, reject) => {
      mgr.signinRedirect()
      .then( () => {
        return resolve(true);
      })
      .catch( err => {
        console.log(err);
        return reject(false);
      });
    });
  }

  // Redirect of the current window to the end session endpoint
  signOut() {
    mgr.signoutRedirect().then( resp => {
      console.log(signedOut, resp);
    }).catch( err => {
      console.log(err);
    });
  }

  // Get the profile of the user logged in
  getProfile() {
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          return resolve(undefined);
        } else {
          return resolve(user.profile);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Get the token id
  getIdToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.id_token);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Get the session state
  getSessionState() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.session_state);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Get the access token of the logged in user
  getAcessToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.access_token);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Takes the scopes of the logged in user
  getScopes() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.scopes);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }

  // Get the user roles logged in
  getRole() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgr.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.profile.role);
        }
      }).catch( err => {
        console.log(err);
        return reject(err);
      });
    });
  }
}
