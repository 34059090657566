
import { getHeaderConfiguration, getClientAvailableLanguajes, getFeatureFlag } from "../../infrastructure/apiServices/masterData.service";
import { Constants } from "../../domain/enum/Constants";
import Cookies from "js-cookie";

export const loadCommonData = function(vm, app, i18n){
  getClientAvailableLanguajes().then((response: any) => {
    vm.availableLanguajes = response.data;
    i18n.locale = app.getLanguaje(response.data.defaultLanguage);
    vm.getAllData();
  }).catch(error => {
    i18n.locale = Constants.language.ES_ES;
    vm.getAllData();
  });

  Promise.all([getFeatureFlag(), getHeaderConfiguration()]).then((response: any) => {
    // FEATURE FLAGS
    response[0].data.map(element => app.featureFlags[element] = true);
    app.isLanguajeLoad = true;

    // COOKIES
    vm.cookie.hasOptanonAlertBoxClosed = Cookies.get('OptanonAlertBoxClosed') !== undefined;
    if (vm.cookie.hasOptanonAlertBoxClosed) {
      vm.cookie.hasAcceptedAnalysisCookies = Cookies.get('AcceptedAnalysisCookies') !== undefined;
    }
    vm.cookie.setConfigCookies();

    // HEADER CONFIG
    vm.headerConfig = response[1].data;
    const link: any = document.querySelector("link[rel~='icon']");
    let title = document.getElementById('title');
    if (vm.headerConfig.favicon !== null && vm.headerConfig.favicon.image !== null) {
      link.href = 'data:image;base64,' + vm.headerConfig.favicon.image;
      title.innerText= vm.headerConfig.favicon.title;
    } else {
      link.href = './convoca_logo.png';
      title.innerText= 'Oferta de empleo público';
    }
    if ((vm.app.state === 'replacementList' || vm.app.state === 'replacementListDetail') && !vm.headerConfig.replacementListsVisible ||
        (vm.app.state === 'paymentRefund' && !vm.app.featureFlags.paymentRefund)) {
      location.href = app.origin + '/index.html';
    }

    const backgroundColor = vm.headerConfig.backgroundColor || '#1976d2';
    const textColor = vm.headerConfig.textColor || '#ffffff';

    const elements = document.querySelectorAll('.applicant-nav, .applicant-footer');

    elements.forEach(element => {
      (element as HTMLElement).style.setProperty('--bg', backgroundColor);
      (element as HTMLElement).style.setProperty('--color', textColor);
    });

    const border = document.querySelector('.applicant-nav-desktop__item.active');
    (border as HTMLElement).style.setProperty('--border', textColor); 

  }).catch(() => {
    app.isLanguajeLoad = true;
  })
}
