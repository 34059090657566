export class ApmConfig {
    serviceName: string;
    serverUrl: string;
    serviceVersion: string;
    logLevel: string;
    instrument: boolean;
    environment: string;
    active: boolean;

    constructor() {
        this.serviceName = 'applicant';
        this.serverUrl = 'https://785e4a0e19b64dad919f97aadfe24376.apm.westeurope.azure.elastic-cloud.com:443';
        this.serviceVersion = '';
        this.logLevel = 'debug';
        this.instrument = true;
        this.environment = 'localhostApm';
        this.active = this.serverUrl !== '';
    }
}